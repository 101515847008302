import React, { useEffect, useState } from "react";
import { useWeb3Context } from "web3-react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { getConfig } from '../helpers'


function WalletConnected({ children }) {

    const context = useWeb3Context(),
        { active, error } = context,
        [loading, setLoading] = useState(false)


    async function connect() {
        setLoading(true)
        try {
            await context.setConnector('MetaMask')
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        connect()
    }, [])

    let content

    const { network } = getConfig()

    if (loading) {
        content = <CircularProgress />
    } else if (error) {
        console.log(error)
        content = (<div>
            <a href="https://metamask.io/download.html" target="_blank" rel="noreferrer">MetaMask sobre la red {network}</a>
        </div>)
    } else if (!active)
        content = (<div>
            <Button variant="contained" color="secondary" onClick={connect}> Connect Metamask </Button>
        </div>)
    else {
        return children
    }


    return <Container>{content}</Container>
}

export default WalletConnected