
import { TextField, CardContent, Card, Typography, CircularProgress, Button, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getObjId, getContractWithSigner } from '../helpers'
import { useWeb3Context } from 'web3-react'
import EtherscanLink from './EtherscanLink'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CertificateList from './CertificateList'
import Api from '../api'

function CertificateForm() {
  const [loading, setLoading] = useState({ visible: false, message: "" })
  const [txStatus, setTxStatus] = useState("")
  const [course, setCourse] = useState({})
  const { register, handleSubmit } = useForm()
  const { library } = useWeb3Context()
  const contract = getContractWithSigner(library)
  const filter = contract.filters.CourseCreated(null)

  // Load Courses
  const [courseList, setCourseList] = useState([])
  const [newCourse, setNewCourse] = useState(0)
  useEffect(() => {
    const getCourseList = async () => {
      const allcourse = JSON.parse(JSON.stringify(await Api.courses()))
      const courses = allcourse.map(c => {
        return ({ ...c, label: `${c.courseName} - ${c.endDate}` })
      })

      setCourseList(courses)
    }
    getCourseList()

    contract.on(filter, (params) => {
      setNewCourse(newCourse + 1)
    })

    return () => {
      contract.removeAllListeners(filter)
    }

  }, [newCourse]);

  // End Load Courses


  async function issueCertificate(formData) {
    const certId = getObjId(formData);
    try {
      setTxStatus("")
      setLoading({ visible: true, message: " Esperando firma" })
      const encryptedName = (await Api.encrypt(formData.studentName))
      const tx = await contract.issueCertificate(certId, formData.courseId, formData.score, formData.studentId, encryptedName)
      setLoading({
        visible: true,
        message: (<span> Esperando confirmación de la transación <EtherscanLink value={tx.hash} /> </span>)
      })
      const receipt = await tx.wait();
      console.log("Receipt: " + receipt);
      setLoading({ visible: false, message: "" })
      setTxStatus((<span> Transaccion confirmada: <EtherscanLink value={tx.hash} />. Curso {formData.courseName} creado.</span>))
    } catch (e) {
      console.log(e)
      setLoading({
        visible: false,
        message: ""
      })
      setTxStatus("")
      return;
    }
  }

  return (<>
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item sm={10} xs={12}>
        <Card>
          {!loading.visible && (<CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Issue Certificate
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(issueCertificate)}>
              <Autocomplete
                id="course"
                options={courseList}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                style={{ width: 600 }}
                value={course}
                onChange={(event, newValue) => {
                  setCourse(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Course" variant="outlined" />}
              /><br /> <br />
              <TextField id="courseId" name="courseId" label="Course Id" inputRef={register({ required: true })} value={(course && course.courseId) ? course.courseId : ""}
                InputProps={{ readOnly: true }} InputLabelProps={{ shrink: true, }} fullWidth={true} required /><br /><br />
              <TextField id="studentId" name="studentId" label="Student Id" inputRef={register({ required: true })} required /><br /><br />
              <TextField id="studentName" name="studentName" label="Student Name" inputRef={register({ required: true })} fullWidth={true} required /><br /><br />
              <TextField id="score" name="score" label="Score" inputRef={register({ required: true })} type="number" required /><br /><br />
              <Button variant="contained" color="primary" type="submit">Issue Certificate</Button>
            </form>
            <Typography gutterBottom variant="body2" component="p">{txStatus}</Typography>
          </CardContent>)}
          {loading.visible && (
            <CardContent>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
                <Grid item sm={10} xs={12}>
                  <Typography gutterBottom variant="h6" component="p">{loading.message}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
    <br />
    <br />
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item sm={10} xs={12}>
        <CertificateList courseId={(course && course.courseId) ? course.courseId : null} label={(course && course.label) ? course.label : null} />
      </Grid>
    </Grid>
  </>
  );
}

export default CertificateForm;