import { ethers } from 'ethers';
import moment from 'moment';
import certContract from './contracts/GoElevateCertificates.json';

const getObjId = (obj) => (ethers.utils.id(JSON.stringify(obj)));

const getConfig = () => ({
  providerURL: process.env.REACT_APP_PROVIDER_URL,
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
  networkId: process.env.REACT_APP_NETWORK_ID,
  etherscanLinkURL: process.env.REACT_APP_ETHERSCAN_URL,
  encryptionURL: process.env.REACT_APP_ENCRIPTION_URL,
  apiTimeout: process.env.REACT_APP_TIMEOUT,
  contractBlockNumberFrom: process.env.REACT_APP_CONTRACT_BLOCK_FROM,
  contractBlockSize: process.env.REACT_APP_CONTRACT_BLOCK_SIZE,
  bearerToken: "DEFAULT"
});

const courseSolidityToJS = (solidityCourse) => ({
  courseName: solidityCourse.courseName,
  startDate: moment.utc(solidityCourse.startDate.toNumber()).format("YYYY-MM-DD"),
  endDate: moment.utc(solidityCourse.endDate.toNumber()).format("YYYY-MM-DD"),
  courseId: solidityCourse.courseId
})

const getContractWithSigner = (library) => new ethers.Contract(getConfig().contractAddress, certContract.abi, library.getSigner())

const provider = new ethers.providers.JsonRpcProvider(getConfig().providerURL);

const certificateEventSolidityToJS = (certificate) => ({
  certificateId: certificate.certificateId,
  courseId: certificate.courseId,
  studentName: certificate.studentName,
  score: certificate.score.toNumber(),
  status: certificate.status
})

export { getObjId, getConfig, courseSolidityToJS, certificateEventSolidityToJS, getContractWithSigner, provider };