import { TextField, CardContent, Card, Typography, CircularProgress, Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getObjId, getContractWithSigner } from '../helpers'
import moment from 'moment'
import { useWeb3Context } from 'web3-react'
import CourseList from './CourseList'
import EtherscanLink from './EtherscanLink'

function CourseForm() {
  const [loading, setLoading] = useState({ visible: false, message: "" })
  const [txStatus, setTxStatus] = useState("")
  const { register, watch, handleSubmit } = useForm()
  const watchFields = watch()
  const defaultDate = moment().endOf("month").format("YYYY-MM-DD")
  const { library } = useWeb3Context()
  const contract = getContractWithSigner(library)

  async function createCourse(formData) {
    const courseId = getObjId(formData);
    const startDateInMillis = moment.utc(formData.startDate).valueOf();
    const endDateInMillis = moment.utc(formData.endDate).valueOf();
    try {
      setTxStatus("")
      setLoading({ visible: true, message: " Esperando firma" })
      const tx = await contract.createCourse(courseId, formData.courseName, startDateInMillis, endDateInMillis)
      setLoading({
        visible: true,
        message: (<span> Esperando confirmación de la transación <EtherscanLink value={tx.hash} /> </span>)
      })
      await tx.wait();
      setLoading({ visible: false, message: "" })
      setTxStatus((<span> Transaccion confirmada: <EtherscanLink value={tx.hash} />. Curso {formData.courseName} creado.</span>))
    } catch (e) {
      console.log(e)
      setLoading({
        visible: false,
        message: ""
      })
      setTxStatus("")
      return;
    }
  }

  return (<>
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item sm={8} xs={12}>
        <Card>
          {!loading.visible && (<CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Create Course
                        </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(createCourse)}>
              <TextField id="courseName" name="courseName" label="Course Name" inputRef={register({ required: true })} fullWidth={true} required /><br /><br />
              <TextField id="startDate" name="startDate" label="Start Date" inputRef={register({ required: true })} type="date" defaultValue={defaultDate} required /><br /><br />
              <TextField id="endDate" name="endDate" label="End Date" inputRef={register({ required: true })} type="date" defaultValue={defaultDate} required /><br /><br />
                          Course ID: {getObjId(watchFields)}<br /><br />
              <Button variant="contained" color="primary" type="submit">
                Create Course
                          </Button>
            </form>
            <Typography gutterBottom variant="body2" component="p">{txStatus}</Typography>
          </CardContent>)}
          {loading.visible && (
            <CardContent>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
                <Grid item sm={8} xs={12}>
                  <Typography gutterBottom variant="h6" component="p">{loading.message}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
    <br />
    <br />
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item sm={8} xs={12}>
        <CourseList />
      </Grid>
    </Grid>
  </>
  );
}

export default CourseForm;
