import EtherscanLink from './EtherscanLink'

export default function PrintCert(props) {

    const certificate = props.certificate;

    return (
        <div className="cert_border_out">
            <div className="cert_border_in">
                <div></div><br />
                <div className="cert_title"><b>Certificate of Completion</b></div><br />
                <div><i>This is to certify that</i></div><br />
                <div><b>{certificate.studentNameDecrypted}</b></div><br />
                <div><i>has completed the course</i></div> <br />
                <div><b>{certificate.courseName}</b></div> <br />
                <div><i>with score of </i><b>{certificate.score}</b></div> <br />
                <div><i>Dates</i></div> <br />
                <div><i>From </i><b>{certificate.startDate}</b><i> to </i><b>{certificate.endDate}</b></div><br />
                <div><i>View on the blockchain</i></div> <br />
                <div><b><EtherscanLink value={certificate.transactionHash} /></b></div><br />
            </div>
        </div>
    );
}