import { CardContent, Card, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useState, useEffect } from 'react';
import { getContractWithSigner } from '../helpers'
import { useWeb3Context } from 'web3-react'
import EtherscanLink from './EtherscanLink'
import Api from '../api'
import Loading from './Loading'

function CertificateList(props) {
  const [certificateList, setCertificateList] = useState([])
  const [newCertificate, setNewCertificate] = useState(0)
  const { library } = useWeb3Context()
  const contract = getContractWithSigner(library)

  const filter = contract.filters.CertificateIssued(props.certificateId ? props.certificateId : null, props.courseId ? props.courseId : null)

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'certificateId', headerName: 'Certificate ID', width: 400 },
    { field: 'courseId', headerName: 'Course ID', width: 150 },
    { field: 'studentName', headerName: 'Student Name', width: 150 },
    { field: 'decryptedStudentName', headerName: 'Decrypted', width: 150 },
    { field: 'score', headerName: 'Score', width: 80 },
    { field: 'transactionHash', headerName: 'Transaction', width: 250, renderCell: EtherscanLink }]

  useEffect(() => {
    const getCertificateList = async () => {
      Loading.showLoading()

      Api.certificates(props.courseId).then(certificates => {
        setCertificateList(certificates)
        Loading.removeLoading()
      }).catch(err => {
        console.log("Error searching certificates. " + err)
        Loading.removeLoading()
      })
    }

    getCertificateList()

    contract.on(filter, (params) => {
      setNewCertificate(newCertificate + 1)
    })
    return () => {
      contract.removeAllListeners(filter)
    }
  }, [props, newCertificate]);


  return (<Card>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        Certificates {props.label && (<span>for {props.label} </span>)}
      </Typography>
      <div style={{ height: 300, width: '100%' }}>
        <DataGrid rows={certificateList} columns={columns} />
      </div>
    </CardContent>
  </Card>
  );
}

export default CertificateList;
