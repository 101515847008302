import { CardContent, Card, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useState, useEffect } from 'react';
import { getContractWithSigner } from '../helpers'
import { useWeb3Context } from 'web3-react'
import EtherscanLink from './EtherscanLink'
import Api from '../api'
import Loading from './Loading'

function CourseList() {
  const [courseList, setCourseList] = useState([])
  const [newCourse, setNewCourse] = useState(0)
  const { library } = useWeb3Context()
  const contract = getContractWithSigner(library)
  const filter = contract.filters.CourseCreated(null)

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'courseId', headerName: 'Course ID', width: 150 },
    { field: 'courseName', headerName: 'Course Name', width: 250 },
    { field: 'startDate', headerName: 'Start date', type: 'date', width: 120 },
    { field: 'endDate', headerName: 'End date', type: 'date', width: 120 },
    { field: 'transactionHash', headerName: 'Transaction', width: 250, renderCell: EtherscanLink }]

  useEffect(() => {
    const getCourseList = async () => {
      Loading.showLoading()
      Api.courses().then(courses => {
        setCourseList(courses)
        Loading.removeLoading()
      }).catch(err => {
        console.log("Error searching courses. " + err)
        Loading.removeLoading()
      })
    }
    getCourseList()

    contract.on(filter, (params) => {
      setNewCourse(newCourse + 1)
    })

    return () => {
      contract.removeAllListeners(filter)
    }

  }, [newCourse]);

  return (<Card>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        Courses
      </Typography>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid rows={courseList} columns={columns} />
      </div>
    </CardContent>
  </Card>
  );
}

export default CourseList;
