import { TextField, CardContent, Card, Typography, Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Api from '../api'

function SecurityForm() {
  const [message, setMessage] = useState('');
  const { register, handleSubmit } = useForm()
  async function setBearerTolen(formData) {
    var token = formData.bearerToken
    Api.validatejwt(token).then(response => {
      if (response.status === 204) {
        sessionStorage.setItem("Bearer", token)
        window.location.reload(false);
      } else {
        setMessage("Token could not be setted!");
      }
    }).catch(err => {
      setMessage("Error setting token. " + err);
    })
    return;
  }

  return (<>
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item sm={8} xs={12}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">Set Bearer Token</Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(setBearerTolen)}>
              <TextField id="bearerToken" name="bearerToken" label="Bearer Token" inputRef={register({ required: true })} fullWidth={true} required /><br /><br />
              <Button variant="contained" color="primary" type="submit">Set Token</Button>
            </form>
            {message && (
              <p className="error"> {message} </p>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>

  </>
  );
}

export default SecurityForm;
