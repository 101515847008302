import React, { useState } from 'react';
import { TextField, Button, Container, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Api from '../api'
import PrintCert from './PrintCert'
import Loading from './Loading'

function ViewCertificate(props) {

  const [certificate, setCertificate] = useState()
  const [found, setFound] = useState()
  const { register, handleSubmit } = useForm({ defaultValues: { certificateId: props.certId } })

  function getCertificateForm(form) {
    const certId = form.certificateId;
    getCertificate(certId)
  }

  async function getCertificate(certId) {
    Loading.showLoading()
    Api.certificatesDetail(certId)
      .then(response => {
        setFound(true)
        setCertificate(response.data)
        Loading.removeLoading()
      }).catch(error => {
        console.log("ERROR", error)
        setFound(false)
        setCertificate(null)
        Loading.removeLoading()
      })
  }

  let content = ""

  if (found === true && certificate) {
    if (certificate.certificateStatus === 'ACTIVE') {
      content = (<PrintCert certificate={certificate} />)
    } else {
      (<div> Certificate revoked</div>)
    }
  } else {
    if (found === false) {
      content = (<div>Invalid certificate</div>)
    } // else show nothing
  }

  return (
    <div className="App">
      <header className="app-header">
        <form noValidate autoComplete="off" onSubmit={handleSubmit(getCertificateForm)}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item sm={8} xs={8}>
              <TextField id="certificateId" name="certificateId" label="Certificate Id" inputRef={register({ required: true, maxLength: 66 })} fullWidth={true} required />
            </Grid>
            <Grid item sm={2} xs={4}>
              <Button type="submit" variant="contained" color="primary">
                View Certificate
              </Button>
            </Grid>
          </Grid>
        </form>
      </header>
      <Container>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          {content}
        </Grid>
      </Container>
    </div>
  );
}

export default ViewCertificate;
