import React from 'react';
import SecurityForm from './components/SecurityForm';
import CertificateForm from './components/CertificateForm';
import SearchCertificate from './components/SearchCertificate';
import CourseForm from './components/CourseForm';
import CertStatus from './components/CertStatus'
import WalletConnected from './components/WalletConnected';
import Connectors from './connectors';
import Web3Provider from 'web3-react'
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { Router, Link } from "@reach/router";

export default function App() {
  return (
    <div>
      <Web3Provider connectors={Connectors} libraryName={'ethers.js'}>
        <WalletConnected>
          <AppBar position="static">
            <Toolbar className="toolbar">
              <Typography variant="h6">
                <div>
                  <div style={{ float: "left", clear: "none" }}>
                    <Link to="/">Home</Link>
                    <Link to="/security">Security</Link>
                  </div>
                  <div style={{ float: "right", clear: "none", display: sessionStorage.getItem("Bearer") ? "" : "none" }}>
                    <Link to={sessionStorage.getItem("Bearer") ? "/courses" : "#"}>Courses</Link>
                    <Link to={sessionStorage.getItem("Bearer") ? "/certificates" : "#"}>Certificates</Link>
                    <Link to={sessionStorage.getItem("Bearer") ? "/disable_cert" : "#"}>Disable Cert</Link>
                    <Link to={sessionStorage.getItem("Bearer") ? "/show_detail" : "#"}>Cert Detail</Link>
                  </div>
                </div>
              </Typography>
            </Toolbar>
          </AppBar>
          <Router>
            <SecurityForm path="/security" />
            <CertificateForm path={sessionStorage.getItem("Bearer") ? "/certificates" : "#"} />
            <CourseForm path={sessionStorage.getItem("Bearer") ? "/courses" : "#"} />
            <CertStatus path={sessionStorage.getItem("Bearer") ? "/disable_cert" : "#"} />
            <SearchCertificate path={sessionStorage.getItem("Bearer") ? "/show_detail" : "#"} />
          </Router>
        </WalletConnected>
      </Web3Provider>
    </div >
  );
}
