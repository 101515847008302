import { TextField, CardContent, Card, Typography, CircularProgress, Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getContractWithSigner } from '../helpers'
import { useWeb3Context } from 'web3-react'
import EtherscanLink from './EtherscanLink'

function CertStatus() {
  const [loading, setLoading] = useState({ visible: false, message: "" })
  const [txStatus, setTxStatus] = useState("")
  const { register, handleSubmit } = useForm()
  const { library } = useWeb3Context()
  const contract = getContractWithSigner(library)

  async function changeStatus(formData) {
    try {
      setTxStatus("")
      setLoading({ visible: true, message: "Esperando firma" })
      const tx = await contract.changeCertStatus(formData.certId, 1)
      setLoading({
        visible: true,
        message: (<span> Esperando confirmación de la transación <EtherscanLink value={tx.hash} /> </span>)
      })
      console.log('TRANSACTION 1:', tx);
      await tx.wait();
      console.log('TRANSACTION 2:', tx);
      setLoading({ visible: false, message: "" })
      setTxStatus((<span> Transaccion confirmada: <EtherscanLink value={tx.hash} />. Curso {formData.courseName} deshabilitado.</span>))
    } catch (e) {
      console.log(e)
      setLoading({
        visible: false,
        message: ""
      })
      setTxStatus("")
      return;
    }
  }

  return (<>
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item sm={8} xs={12}>
        <Card>
          {!loading.visible && (<CardContent>
            <Typography gutterBottom variant="h5" component="h2">Change Cert Status</Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(changeStatus)}>
              <TextField id="certId" name="certId" label="Cert Id" inputRef={register({ required: true })} fullWidth={true} required /><br /><br />
              <Button variant="contained" color="primary" type="submit">Change Status</Button>
            </form>
            <Typography gutterBottom variant="body2" component="p">{txStatus}</Typography>
          </CardContent>)}
          {loading.visible && (
            <CardContent>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
                <Grid item sm={8} xs={12}>
                  <Typography gutterBottom variant="h6" component="p">{loading.message}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  </>
  );
}

export default CertStatus;
