import axios from 'axios';
import { getConfig } from './helpers'

axios.defaults.headers = {
    Authorization: 'Bearer ' + sessionStorage.getItem("Bearer")
}

const instance = axios.create({
    baseURL: getConfig().encryptionURL,
    timeout: +getConfig().apiTimeout
});

const decrypt = async (value) => {
    const response = await instance.post('/decrypt', { value })
    return response.data.decrypted
}

const decryptAll = async (prop, newProp, values) => {
    const response = await instance.post('/decryptAll', { prop, newProp, values })
    return response.data
}

const encrypt = async (value) => {
    const response = await instance.post('/encrypt', { value })
    return response.data.encrypted
}

const courses = async () => {
    const response = await instance.get('/courses', {})
    return response.data
}

const certificates = async (courseId) => {
    let response = null
    if (courseId) {
        response = await instance.get('/certificates', { params: { course_id: courseId } })
    } else {
        response = await instance.get('/certificates', {})
    }

    return response.data
}

const certificatesDetail = async (certId) => {
    const response = await instance.get('/certificates/' + certId)
    return response
}

const validatejwt = async (token) => {
    try {
        return await instance.post('/token', { token })
    } catch (error) {
        throw error
    }
}

const Api = { decrypt, decryptAll, encrypt, courses, certificates, validatejwt, certificatesDetail }

export default Api
